.picture {
  display: block;
  height: 60%;
  border-radius: 4px;
  max-width: 400px;
  width: 80vw;
}

.container {
  display: flex;
  justify-content: center;
  width: auto;
}