.button {
  background-color: var(--yellow);
  border-radius: 4px;
  border: none;
  color: var(--gray);
  cursor: pointer;
  aspect-ratio: 1;
}

.text {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.emoji {
  font-size: 25px;
}