.inputContainer{
  display: grid;
  max-width: 500px;
}

.container{
  max-width: 500px;
  margin-top: 30px;
  display: grid;
  row-gap: 30px;
}