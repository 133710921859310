
.container {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.inner {
  background-color: var(--red);
  color: var(--white);
  border-radius: 4px;
  padding: 1em;
  margin: 3em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.xButton {
  color: var(--white);
  background-color: var(--red);
  float: right;
  border: none;
  cursor: pointer;
  font-size: 15px;
}