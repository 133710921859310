.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2em;
}

@media screen and (min-width: 800px) {
  .container{
    grid-template-columns: 1fr 1fr;
  }
}
