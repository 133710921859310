.container{
  display: flex;
  min-height: 48px;
}

.emoji {
  font-size: 32px;
  margin: 0px;
}

.text {
  color: var(--text);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  margin-left: 20px;
  margin-top: 0px;
}

.text a {
  color: var(--yellow);
}