@import "../../colors.css";

.button{
  border: none;
  border-radius: 4px;
  background-color: var(--yellow);
  color: var(--gray);
  margin: 5px;
  cursor: pointer;
}

.button p {
  margin-left: 30px;
  margin-right: 30px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.inactive {
  background-color: var(--yellow-grayout);
  cursor: not-allowed;
}