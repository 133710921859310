.link {
  text-decoration: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  max-height: 50px;
}

.link img {
  height: 32px;
  width: 32px;
  margin: 0px 14px;
}

.container {
  background-color: var(--white);
  display: flex;
  border-radius: 30px;
  align-items: center;
  width: auto;
  z-index: 500;
}

.container p {
  color: var(--gray);
  text-decoration: none;
  font-size: 12px;
  margin-right: 30px;
}

