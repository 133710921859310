.container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%; 
  max-width: 550px;
}

.outerContainer {
  display: flex;
  justify-content: center;
}