:root {
  --background: #1a1b1e;
  --gray: #25262b;
  --white: #f8f8fa;
  --lightGray: #373a40;
  --darkText: #b1b2b5;
  --text: #d4d5d9;
  --red: #c21919;
  --green: #19c235;
  --yellow: #faf21a;
  --yellow-grayout: #82816b;
  --blue: #1971c3;
}
