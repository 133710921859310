.container{
  max-width: 800px;
  margin: 25px;
}

.outer{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

