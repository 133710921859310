.selector{
  border: 2px solid var(--text);
  border-radius: 8px;
  background-color: var(--background);
  transition: 100ms linear background-color;
  cursor: pointer;
}

.selector:hover{
  background-color: var(--lightGray);
}

.container{
  display: grid;
  grid-template-columns: 50px 1fr 50px;
}

.text{
  margin-top: auto;
  margin-bottom: auto;
  color: var(--text);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size:  15px;
}

.circle{
  border-radius: 100%;
  border: 2px solid var(--text);
  width: 20px;
  height: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle{
  border-radius: 100%;
  background-color: var(--text);
  width: 14px;
  height: 14px;
}

.emoji{
  line-height: 16px;
  font-size: 19px;
  margin: auto;
}