.subTitle {
  color: var(--text);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 200;
  text-align: center;
  line-height: 1.5;
  font-size: 22px;
}

.subTitle strong {
  font-weight: 400;
}

.subTitle a {
  color: var(--yellow);
}