@import "../../colors.css";

.input{
  color:var(--text);
  background-color: var(--gray);
  border: 1px solid var(--lightGray);
  font-size: 14px;
  line-height: 30px;
  border-radius: 4px;
  transition: border-color 100ms ease 0s;
}

.input:focus{
  border: 1px solid var(--blue);
  box-shadow: none;
  outline: none;
}

.greenBorder{
  border-color: var(--green);
}

.greenBorder:focus{
  border-color: var(--green);
}

.redBorder{
  border-color: var(--red);
}

.redBorder:focus{
  border-color: var(--red);
}