.container{
  display: grid;
  grid-template-columns: 50px 1fr;
  color: var(--text);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.emoji{
  margin: auto;
}

.container label {
  margin-top: 1em;
  margin-bottom: 1em;
}